import * as Icons from '@ant-design/icons'
import { DateField, DeleteButton, NumberField, useModalForm } from '@refinedev/antd'
import {
  BaseKey,
  HttpError,
  useCreate,
  useDelete,
  useInvalidate,
  useList,
  useOne,
  useUpdate,
} from '@refinedev/core'
import {
  Button,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Modal,
  Select,
  SelectProps,
  Switch,
  Table,
} from 'antd'
import { IVenueSuggestion } from 'interfaces/crm'
import React, { useEffect, useState } from 'react'
import {
  LEAD_URL,
  PRODUCT_URL,
  VENUE_LEAD_URL,
  VENUE_REQUIREMENTS_URL,
  VENUE_SUGGESTION_STATUS_URL,
  VENUE_SUGGESTION_URL,
} from 'urls'
import AddVenueSuggestion from './AddVenueSuggestion'
import { SelectWithDefault } from 'components/input/SelectWithDefault'
import { EditableCell } from 'components/input/EditableCell'

import dayjs from 'antd/node_modules/dayjs'
import type { Dayjs } from 'dayjs'
import { CheckOutlined } from '@ant-design/icons'

export const ShareWithVenue: React.FC<{
  suggestionId: BaseKey
  is_shared: boolean
  venue_id: any
  name: string
  phone: string
  shared_time: Date
}> = ({ suggestionId, is_shared, venue_id, name, phone, shared_time }) => {
  const invalidate = useInvalidate()
  // toogle to show wether shared or not, field name is shared_with_venue
  const [shared, setShared] = useState<boolean>(false)
  // const { visible, close, show } = useModal()
  const { close, modalProps, formProps, show } = useModalForm({
    resource: VENUE_LEAD_URL,
    action: 'create',
    redirect: false,
    onMutationSuccess: () => {
      setShared(true)
    },
  })

  const durationOption: SelectProps['options'] = [
    {
      label: '6 hrs',
      value: '6 hrs',
    },
    {
      label: '12 hrs',
      value: '12 hrs',
    },

    {
      label: '24 hrs',
      value: '24 hrs',
    },
    {
      label: '36hrs',
      value: '36hrs',
    },
    {
      label: '48 hrs',
      value: '48 hrs',
    },
  ]

  useEffect(() => {
    setShared(is_shared)
  }, [is_shared])

  return (
    <>
      {shared ? (
          <DateField
            value={shared_time} format='LLL'>
          </DateField>
      ) : (
        <Button onClick={() => show()}>Share</Button>
      )}

      <Modal {...modalProps}>
        <Form
          {...formProps}
          onFinish={(values: any) =>
            formProps.onFinish!({
              ...values,
              dates: values.date.map((day: any) =>
                dayjs(day).format('YYYY-MM-DD'),
              ),
              venue: venue_id,
              meargi_venue_suggestion: suggestionId,
              name: name,
              phone: phone,
              source: 'Meragi',
            })
          }
          layout="vertical">
          <Form.Item label="Date" name="date">
            <DatePicker
              multiple
              mode="date"
              showTime={{ use12Hours: true }}
              format={'YYYY-MM-DD'}
            />
          </Form.Item>
          <Form.Item label="Spaces" name="space">
            <SelectWithDefault
              mode="multiple"
              allowClear
              size="small"
              popupMatchSelectWidth={false}
              useSelectProps={{
                resource: PRODUCT_URL,
                optionLabel: 'name',
                filters: [
                  {
                    field: 'space_in_venue',
                    operator: 'in',
                    value: [venue_id],
                  },
                ],
              }}
            />
          </Form.Item>
          <Form.Item label="Buyout" name="buyout">
            <Switch />
          </Form.Item>
          <Form.Item label="Duration" name="durations">
            <Select
              mode="multiple"
              placeholder="select Duration"
              defaultValue={[]}
              style={{ width: '100%' }}
              options={durationOption}
            />
          </Form.Item>

          <Form.Item label="Notes" name="notes">
            <Input.TextArea />
          </Form.Item>
          <Form.Item label="Accomodation (No. of Rooms)" name="rooms">
            <InputNumber />
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}

export const NewVenueSuggestion: React.FC<{
  venue_suggestions: IVenueSuggestion[]
  leadId: BaseKey
  name: string
  phone: string
}> = ({ venue_suggestions, leadId, name, phone }) => {
  const { data: trackerData } = useOne<any>({
    resource: 'package_tool/venue_lead_status',
    id: `status_list`,
  })
  const invalidate = useInvalidate()
  const { mutate, isLoading } = useCreate()
  const { mutate: updateMutate } = useUpdate()
  const { mutate: deleteMutate } = useDelete()

  const handleOptionChange = (value: string, suggestionId: BaseKey) => {
    mutate(
      {
        resource: VENUE_SUGGESTION_STATUS_URL,
        values: {
          status: value,
          venue_suggestion: suggestionId,
        },
      },
      {
        onSuccess: () => {
          invalidate({
            resource: LEAD_URL,
            invalidates: ['list'],
          });
        },
      },
    );
  };

  const handleDelete = (suggestionId: BaseKey) => {
    Modal.confirm({
      title: 'Are you sure you want to delete this suggestion?',
      onOk: () => {
        deleteMutate(
          {
            resource: VENUE_SUGGESTION_URL,
            id: suggestionId,
          },
          {
            onSuccess: () => {
              invalidate({
                resource: LEAD_URL,
                invalidates: ['list'],
              });
            },
          },
        )
      }
    })
  }

  return (
    <>
      {venue_suggestions[0] && < Button
          icon={<Icons.ShareAltOutlined />}
          key="Share"
          style={{float: "right", margin: "10px 0px"}}
          onClick={() => {
            window.open(
              `${process.env.REACT_APP_WEB_APP_URL}/${venue_suggestions[0]?.duration?.package}`,
              '_blank',
            )
          }}>
          Share
        </Button>}
      <Table
        pagination={false}
        rowKey={(record) => record.id}
        dataSource={venue_suggestions}
        style={{ padding: '0 16px' }}
      >
        <Table.Column dataIndex={['venue', 'name']} title="Venue" />
        <Table.Column
          dataIndex={['statuses']}
          title="Status"
          render={(value, record: IVenueSuggestion) => {
            return (
              <Select
                dropdownMatchSelectWidth={false}
                value={value[0]?.status}
                options={trackerData?.data?.results.map((t: any) => ({
                  label: t,
                  value: t,
                }))}
                onChange={(value) => handleOptionChange(value, record.id)}
              />
            )
          }}
        />
        <Table.Column
          dataIndex={['quoted_price']}
          title="Quoted Price"
          render={(value, record: IVenueSuggestion) => (
            <EditableCell
              value={value}
              inputType="number"
              url={VENUE_SUGGESTION_URL}
              recordId={record.id}
              field="quoted_price"
              onSuccess={() => invalidate({ resource: LEAD_URL, invalidates: ['list'] })}
            />
          )}
        />

        <Table.Column
          dataIndex={['booked_price']}
          title="Booked Price"
          render={(value, record: IVenueSuggestion) => (
            <EditableCell
              value={value}
              inputType="number"
              url={VENUE_SUGGESTION_URL}
              recordId={record.id}
              field="booked_price"
              onSuccess={() => invalidate({ resource: LEAD_URL, invalidates: ['list'] })}
            />
          )}
        />
        <Table.Column
          dataIndex={['is_shared_with_venue']}
          title="Shared with Venue"
          render={(value, record: IVenueSuggestion) => {
            return (
              <>
              <ShareWithVenue
                is_shared={record.is_shared_with_venue}
                suggestionId={record.id}
                venue_id={record.venue.id}
                name={name}
                phone={phone}
                shared_time={record?.venue_lead[0]?.created_at}
              />
            </>
            )
          }}
        />
        <Table.Column
          title="Actions"
          render={(text, record: IVenueSuggestion) => (
            !record.is_shared_with_venue ? (
              <Button
                icon={<Icons.DeleteOutlined />}
                onClick={() => handleDelete(record.id)}
              >
                Delete
              </Button>
            ) : null
          )}
        />
      </Table>
      <AddVenueSuggestion leadId={leadId} />
    </>
  )
}
